import ApiService from "@/core/services/api/v2/api.service";

const TENDER_ROUTE_LIST_KEY = "TenderRouteList";
const TENDER_ROUTE_FILTER_KEY = "TenderRouteFilter";

const TenderRouteService = {
  getProfile(tenderRouteID) {
    return new Promise(resolve => {
      ApiService.get("tenderRoute/" + tenderRouteID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(tenderRouteID) {
    return new Promise(resolve => {
      ApiService.delete("tenderRoute/" + tenderRouteID)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("tenderRoute", newData)
        .then(response => {
          this.cleanCachedList();
          // Response (if correct) holds the new TenderRouteID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      window.sessionStorage.setItem(
        TENDER_ROUTE_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("tenderRoute", { params: queryParams })
        .then(response => {
          // Return full response, not just "TenderRoutes" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(TENDER_ROUTE_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  listAll(avoidCache) {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(TENDER_ROUTE_LIST_KEY);
      if (cachedList && !avoidCache) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.query("tenderRoute", { params: { Limit: 999 } })
          .then(response => {
            let newList = response.data.response.TenderRoutes;

            window.sessionStorage.setItem(
              TENDER_ROUTE_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(TENDER_ROUTE_LIST_KEY);
  }
};

export default TenderRouteService;
