<template>
  <!-- begin::TenderRoutes edit -->
  <div class="gray-box">
    <div class="manager-title">
      <div>
        {{ $t("TENDER_ROUTES.TITLE_EDIT") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!-- begin::Basic data -->
    <div class="white-box shadow-box">
      <div class="manager-subtitle">
        {{ $t("TENDER_ROUTES.SUBTITLE") }}
      </div>

      <div class="row">
        <div class="form-group col-lg-4">
          <label>
            {{ $t("TENDER_ROUTES.ORIGIN") }}
          </label>
          <v-text-field
            class="form-control"
            :value="
              TenderRouteData.OriginProvince
                ? TenderRouteData.OriginProvince.Name
                : ''
            "
            readonly
          />
        </div>

        <div class="form-group col-lg-2">
          &nbsp;
        </div>

        <div class="form-group col-lg-4">
          <label>
            {{ $t("TENDER_ROUTES.DESTINATION") }}
          </label>
          <v-text-field
            class="form-control"
            :value="
              TenderRouteData.DestinationProvince
                ? TenderRouteData.DestinationProvince.Name
                : ''
            "
            readonly
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 form-group">
          <label>
            {{ $t("TENDER_ROUTES.ORIGIN_ADDRESS") }}
          </label>
          <div class="row mb-3">
            <div class="col-lg-12">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginAddress
                    ? TenderRouteData.OriginAddress.Nick
                    : $t('TENDER_ROUTES.ANY_ADDRESS_IN_PROVINCE')
                "
                readonly
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-12">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginAddress
                    ? TenderRouteData.OriginAddress.TradeName
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                readonly
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginAddress
                    ? TenderRouteData.OriginAddress.Street
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.FULL_ADDRESS_2')"
                readonly
              />
            </div>
            <div class="col-lg-4">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginAddress
                    ? TenderRouteData.OriginAddress.PostalCode
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
                readonly
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginAddress
                    ? TenderRouteData.OriginAddress.City
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
                readonly
              />
            </div>
            <div class="col-lg-6">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.OriginAddress
                    ? TenderRouteData.OriginProvince.Name
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.PROVINCE')"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="col-lg-6 form-group">
          <label>
            {{ $t("TENDER_ROUTES.DESTINATION_ADDRESS") }}
          </label>
          <div class="row mb-3">
            <div class="col-lg-12">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationAddress
                    ? TenderRouteData.DestinationAddress.Nick
                    : $t('TENDER_ROUTES.ANY_ADDRESS_IN_PROVINCE')
                "
                readonly
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-12">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationAddress
                    ? TenderRouteData.DestinationAddress.TradeName
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.TRADE_NAME')"
                readonly
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-8">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationAddress
                    ? TenderRouteData.DestinationAddress.Street
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.FULL_ADDRESS_2')"
                readonly
              />
            </div>
            <div class="col-lg-4">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationAddress
                    ? TenderRouteData.DestinationAddress.PostalCode
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.POSTAL_CODE_2')"
                readonly
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationAddress
                    ? TenderRouteData.DestinationAddress.City
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.CITY')"
                readonly
              />
            </div>
            <div class="col-lg-6">
              <v-text-field
                class="form-control"
                :value="
                  TenderRouteData.DestinationAddress
                    ? TenderRouteData.DestinationProvince.Name
                    : ''
                "
                :placeholder="$t('FREQUENT_ADDRESSES.PROVINCE')"
                readonly
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary"
          @click="
            $router.push({
              path: '/manager/addresses',
              query: { ActiveTab: 'tender' }
            })
          "
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>
      </div>
    </div>
    <!-- end::Basic data -->

    <!-- begin::Fares -->
    <div class="manager-subtitle mt-15 pl-18">
      {{ $t("TENDER_ROUTES.ROUTE_TRANSPORTERS") }}
    </div>

    <!--begin::Row Searching-->
    <div v-if="Fares.ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <div v-else>
      <!--begin::Table Results-->
      <v-data-table
        v-if="Fares.ItemsList.length > 0"
        class="white-table shadow-box"
        :headers="Fares.TableHeaders"
        :items="Fares.ItemsList"
        :items-per-page="Fares.ItemsPerPage"
        :options.sync="Fares.options"
        :server-items-length="Fares.ItemsTotal"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.TransporterCompany.TradeName }}
            </td>
            <td>
              {{ formatPrice(item.PricePerTruck, item.Currency) }}
            </td>
            <td>
              {{ formatPrice(item.PricePerUnit, item.Currency) }}
            </td>
            <td>
              <span
                class="white-box border-2 p-3 font-size-sm text-uppercase"
                :class="[
                  item.Active === 1 ? 'border-tertiary text-tertiary' : ''
                ]"
                style="white-space: nowrap"
              >
                {{ $t("TENDER_ROUTES.ACTIVE." + item.Active) }}
              </span>
            </td>
            <td>
              <span
                v-if="item.Active === 0"
                class="action-icon mr-4 bg-tertiary border-tertiary"
                @click="acceptFare(item)"
              >
                <inline-svg src="/media/icons/validation-check.svg" />
              </span>

              <span
                v-if="item.Active === 0"
                class="action-icon mr-4y"
                @click="rejectFare(item)"
              >
                <inline-svg src="/media/icons/cross-primary.svg" />
              </span>

              <span
                v-if="item.Active === 1"
                class="action-icon"
                @click="deleteFare(item)"
              >
                <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
      <!--end::Table Results-->

      <!--begin::Row Pagination-->
      <div v-if="Fares.ItemsList.length > 0" class="p-0 row">
        <div
          class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
        >
          <div>
            {{
              $t("GENERIC.PAGINATION_SHOW", {
                from: Fares.ItemsFrom,
                to: Fares.ItemsTo,
                total: Fares.ItemsTotal
              })
            }}
          </div>

          <v-pagination
            v-model="Fares.ItemsCurrentPage"
            :length="Fares.ItemsPages"
            @input="searchFares(true)"
            :previous-aria-label="$t('GENERIC.PREVIOUS')"
            :next-aria-label="$t('GENERIC.NEXT')"
          />

          <div class="d-flex align-items-baseline py-3">
            <v-select
              class="form-control form-control-sm mr-0 border-0 bg-light"
              style="max-width: 5em;"
              v-model="Fares.ItemsPerPage"
              single-line
              :items="Fares.ItemsPerPageOptions"
              @change="updateItemsPerPage"
            />
            <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
          </div>
        </div>
      </div>
      <!--end::Row Pagination-->

      <!--begin::Row Zero-->
      <div v-if="Fares.ItemsList.length < 1" class="search-info">
        <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
        {{ $t("GENERIC.NO_RESULTS") }}
      </div>
      <!--end::Row Zero-->
    </div>
    <!--end::Fares-->
  </div>
  <!-- end::TenderRoutes edit -->
</template>

<script>
import Swal from "sweetalert2";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import FareTenderRouteService from "@/core/services/api/v2/faretenderroute.service";
import TenderRouteService from "@/core/services/api/v2/tenderroute.service";

export default {
  name: "TenderRoutesEdit",
  data() {
    return {
      TenderRouteID: 0,
      TenderRouteData: {
        OriginProvince: null,
        DestinationProvince: null,
        OriginAddress: null,
        DestinationAddress: null
      },
      Fares: {
        TableHeaders: [
          {
            text: this.$t("FREQUENT_ADDRESSES.NICK"),
            value: "TransporterCompany.TradeName"
          },
          {
            text: this.$t("FARES.COMMON.PRICE_PER_TRUCK_SHORT"),
            value: "PricePerTruck"
          },
          {
            text: this.$t("FARES.COMMON.PRICE_PER_UNIT_SHORT"),
            value: "PricePerUnit"
          },
          {
            text: this.$t("TENDER_ROUTES.STATUS"),
            value: "Active"
          },
          { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false }
        ],
        options: {},
        SortBy: "",
        SortDesc: false,
        ItemsList: [],
        ItemsSearching: true,
        ItemsFrom: 0,
        ItemsTo: 0,
        ItemsTotal: 0,
        ItemsPerPage: 5,
        ItemsPages: 1,
        ItemsCurrentPage: 1,
        ItemsPerPageOptions: [
          { text: 5, value: 5 },
          { text: 10, value: 10 },
          { text: 25, value: 25 },
          { text: 50, value: 50 }
        ]
      }
    };
  },
  created() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
  },
  mounted() {
    // Pick route ID
    if (this.$route.params.id) {
      this.TenderRouteID = parseInt(this.$route.params.id);
    } else {
      this.TenderRouteID = -1;
    }

    this.loadRouteData();
  },
  methods: {
    loadRouteData() {
      this.Fares.ItemsList = [];
      TenderRouteService.getProfile(this.TenderRouteID).then(response => {
        if (response === 404) {
          this.$router.push({
            path: "/manager/addresses",
            query: { ActiveTab: "tender" }
          });
        } else {
          this.TenderRouteData = response;
          this.searchFares(false);

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    searchFares(keepCurrentPage) {
      this.Fares.ItemsSearching = true;
      this.Fares.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.Fares.ItemsCurrentPage = 1;
      }

      const limit = this.Fares.ItemsPerPage;
      const offset =
        this.Fares.ItemsCurrentPage === 0
          ? 0
          : limit * (this.Fares.ItemsCurrentPage - 1);

      FareTenderRouteService.searchFromMySender(
        this.TenderRouteID,
        this.Fares.SortBy,
        this.Fares.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.Fares.ItemsList = [];
          this.Fares.ItemsPages = 1;
        } else {
          this.Fares.ItemsList = rawResponse.response.FareTenderRoutes;

          this.Fares.ItemsTotal = rawResponse.total;
          this.Fares.ItemsPages = Math.ceil(
            this.Fares.ItemsTotal / this.Fares.ItemsPerPage
          );
          this.Fares.ItemsFrom =
            (this.Fares.ItemsCurrentPage - 1) * this.Fares.ItemsPerPage + 1;
          this.Fares.ItemsTo = this.Fares.ItemsFrom; /// TO-DO + this.Fares.ItemsList.length - 1;
        }

        this.Fares.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.Fares.SortBy = event[0];
        this.Fares.SortDesc = false;
        this.Fares.ItemsCurrentPage = 1;
        this.searchFares(false);
      }
    },
    updateSortDesc(event) {
      this.Fares.SortDesc = event.length > 0 ? event[0] : this.Fares.SortDesc;
      this.Fares.ItemsCurrentPage = 1;
      this.searchFares(false);
    },
    updateItemsPerPage(newValue) {
      this.Fares.ItemsPerPage = newValue;
      this.Fares.ItemsCurrentPage = 1;
      this.searchFares(false);
    },

    changeItemsCurrentPage() {
      this.searchFares(true);
    },

    formatPrice(price, currency) {
      if (price.length === 0) {
        return "";
      } else {
        let price2 = price.toFixed(2);
        let userLang = i18nService.getActiveLanguage();
        if (userLang !== "en") {
          price2 = price2.replace(".", ",");
        }

        return price2 + " " + currency;
      }
    },

    acceptFare(item) {
      FareTenderRouteService.accept(
        this.TenderRouteID,
        item.FareTenderRouteID
      ).then(statusCode => {
        if (statusCode >= 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.DELETE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          this.searchFares(true);
        }
      });
    },

    rejectFare(item) {
      FareTenderRouteService.reject(
        this.TenderRouteID,
        item.FareTenderRouteID
      ).then(statusCode => {
        if (statusCode >= 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.DELETE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          this.searchFares(true);
        }
      });
    },

    deleteFare(item) {
      Swal.fire({
        title:
          this.$i18n.t("GENERIC.DELETE_ALERT") +
          "\n" +
          item.TransporterCompany.TradeName,
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
        cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          FareTenderRouteService.reject(
            this.TenderRouteID,
            item.FareTenderRouteID
          ).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("GENERIC.DELETE_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              this.searchFares(true);
            }
          });
        }
      });
    }
  }
};
</script>
