import ApiService from "@/core/services/api/v2/api.service";

const FARE_TENDER_ROUTE_FILTER_KEY = "FareTenderRouteFilter";

const FareTenderRouteService = {
  // Sender side

  searchFromMySender(tenderRouteID, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      let queryUrl = "tenderRoute/" + tenderRouteID + "/fare";
      ApiService.query(queryUrl, { params: queryParams })
        .then(response => {
          // Return full response, not just "FareTenderRoutes" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  accept(tenderRouteID, fareTenderRouteID) {
    return new Promise(resolve => {
      let putUrl =
        "tenderRoute/" + tenderRouteID + "/fare/" + fareTenderRouteID;

      ApiService.put(putUrl)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  reject(tenderRouteID, fareTenderRouteID) {
    return new Promise(resolve => {
      let deleteUrl =
        "tenderRoute/" + tenderRouteID + "/fare/" + fareTenderRouteID;

      ApiService.delete(deleteUrl)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // Transporter side

  countToMyTransporterWaitingPrice() {
    return new Promise(resolve => {
      ApiService.query("fare/tender/countWaitingPrice")
        .then(response => {
          resolve(response.data.response.CountWaitingPrice);
        })
        .catch(() => {
          resolve(0);
        });
    });
  },

  searchToMyTransporter(searchText, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("fare/tender/search", { params: queryParams })
        .then(response => {
          // Return full response, not just "FareTenderRoutes" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  searchToMyTransporterFromSender(
    senderCompanyID,
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    filterByActive,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      filterByActive = parseInt(filterByActive);
      if (!isNaN(filterByActive) && filterByActive >= -1) {
        queryParams.Active = filterByActive;
      }

      window.sessionStorage.setItem(
        FARE_TENDER_ROUTE_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      let queryUrl = "fare/tender/searchBySender/" + senderCompanyID;
      ApiService.query(queryUrl, { params: queryParams })
        .then(response => {
          // Return full response, not just "FareTenderRoutes" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(
      FARE_TENDER_ROUTE_FILTER_KEY
    );

    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  cleanCachedFilters() {
    window.sessionStorage.removeItem(FARE_TENDER_ROUTE_FILTER_KEY);
  },

  getProfile(senderCompanyID, fareTenderRouteID) {
    return new Promise(resolve => {
      let getUrl =
        "fare/tender/profile/" + senderCompanyID + "/" + fareTenderRouteID;

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(senderCompanyID, fareTenderRouteID, newData) {
    return new Promise(resolve => {
      let putUrl =
        "fare/tender/profile/" + senderCompanyID + "/" + fareTenderRouteID;

      ApiService.put(putUrl, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(senderCompanyID, fareTenderRouteID) {
    return new Promise(resolve => {
      let deleteUrl =
        "fare/tender/profile/" + senderCompanyID + "/" + fareTenderRouteID;

      ApiService.delete(deleteUrl)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default FareTenderRouteService;
